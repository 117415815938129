export interface IPrebindHandler {
    (value: string, element: HTMLElement): string;
}

export interface IPrebindAttribute {
    attribute: string;
    attributeValue: any;
    method: IPrebindHandler;
}

export interface IPrebindResolver {
    registerPrebindHelper(helperName: string, handler: IPrebindHandler): void;
    hasHelper(helperName: string): boolean;
    getHelper(helperName: string): IPrebindHandler;
    canApplyPrebind(element: HTMLElement): boolean;
    applyToElement(element: HTMLElement): void;
    hasNotAppliedPrebind(element: HTMLElement): boolean;
}

export class PrebindResolver implements IPrebindResolver {
    public static prebindString = "prebind";
    private prebindAppliedValue = "appliedPrebind";
    private helpers: { [helperName: string]: IPrebindHandler } = {};

    public registerPrebindHelper(helperName: string, handler: IPrebindHandler): void {
        if (typeof (helperName) !== "string" || helperName === "") {
            throw "You must define a name for the prebind helper.";
        }
        if (typeof (handler) === "undefined" || handler === null) {
            throw "You must define a handler for the prebind helper.";
        }
        if (this.helpers.hasOwnProperty(helperName)) {
            console.warn(`A prebind handler already exists for '${helperName}'. It will be ignored`);
            return;
        }
        this.helpers[helperName] = handler;
    }

    public hasHelper(helperName: string): boolean {
        return this.helpers.hasOwnProperty(helperName);
    }

    public getHelper(helperName: string): IPrebindHandler {
        if (!this.hasHelper(helperName)) {
            throw `'${helperName}' has no registered helper.`;
        }
        return this.helpers[helperName];
    }

    public canApplyPrebind(element: HTMLElement): boolean {
        return !!element.dataset;
    }

    public hasNotAppliedPrebind(element: HTMLElement): boolean {
        return element.dataset[this.prebindAppliedValue] !== "true";
    }

    public applyToElement(element: HTMLElement): void {
        try {
            const attributes = this.getPrebindingAttributesForElement(element);
            if (attributes.length > 0) {
                element.dataset[this.prebindAppliedValue] = "true";
            }
            attributes.forEach(attributes => this.applyPrebindToElement(attributes, element));
        } catch (exception) {
            console.warn("(CoveoForSitecore) prebinding failed on element.", element, exception);
        }
    }

    private getPrebindingAttributesForElement(element: HTMLElement): IPrebindAttribute[] {
        return this.getAllPrebindingAttributesNames(element).map(key => {
            const methodName = element.dataset[key];
            const attribute = this.lowerCaseFirstCharacter(this.removePrebindPrefix(key));
            return {
                attribute: attribute,
                attributeValue: element.dataset[attribute] || "",
                method: this.getHelper(methodName)
            };
        });
    }

    private getAllPrebindingAttributesNames(element: HTMLElement): string[] {
        return Object.keys(element.dataset)
            .filter(key => this.stringStartsWithPrebindingString(key))
            .filter(key => {
                const helperName = element.dataset[key];
                return this.filterAndWarnNonExistingHelperName(helperName, element);
            });
    }

    private stringStartsWithPrebindingString(value: string): boolean {
        return value.lastIndexOf(PrebindResolver.prebindString) === 0;
    }

    private filterAndWarnNonExistingHelperName(helperName: string, element: HTMLElement): boolean {
        const hasHelperWithName: boolean = this.hasHelper(helperName);

        if (!hasHelperWithName) {
            console.warn(`(CoveoForSitecore) an unregistered prebinding '${helperName}' was set on the element. Register this method using Prebinding.registerPrebindHelper("${helperName}", handler).`, element);
        }

        return hasHelperWithName;
    }

    private removePrebindPrefix(attributeName: string): string {
        return attributeName.substring(PrebindResolver.prebindString.length);
    }

    private lowerCaseFirstCharacter(value: string): string {
        return value.charAt(0).toLowerCase() + value.slice(1);
    }

    private applyPrebindToElement(prebind: IPrebindAttribute, element: HTMLElement): void {
        element.dataset[prebind.attribute] = prebind.method(prebind.attributeValue, element);
    }
}
