export function nodeListToArray(elements: HTMLCollectionOf<Element> | NodeListOf<Element>): HTMLElement[] {
    const array: HTMLElement[] = [];
    for (var i = 0, length = elements.length; i < length; i++) {
        array.push(elements.item(i) as HTMLElement);
    }
    return array;
}

export function cloneElementSafelyForAccessibility(element: HTMLElement): HTMLElement {
    const clone = element.cloneNode(true) as HTMLElement;
    return removeAllIDAttributes(clone);
}

export function removeAllIDAttributes(element: HTMLElement): HTMLElement {
    element.removeAttribute("id");
    const children = element.querySelectorAll("*[id]");
    const length = children.length;
    for (let i = 0; i < length; i++) {
        children.item(i).removeAttribute("id");
    }
    return element;
}
