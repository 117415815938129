import { IPrebindResolver, PrebindResolver, IPrebindHandler } from "../resolvers/prebinding/PrebindResolver";
import * as SortCriteriaPreprocessor from "../resolvers/sortCriteria/SortCriteriaPreprocessor"

export class Prebinding {
    private static processor: IPrebindResolver = new PrebindResolver();

    static registerPrebindHelper(helperName: string, handler: IPrebindHandler): void {
        this.processor.registerPrebindHelper(helperName, handler);
    }

    static getHelper(helperName: string): IPrebindHandler {
        return this.processor.getHelper(helperName);
    }

    static applyPrebindingOnElement(rootElement: HTMLElement): void {
        if (this.processor.canApplyPrebind(rootElement) && this.processor.hasNotAppliedPrebind(rootElement)) {
            this.processor.applyToElement(rootElement);
        }
    }

    static applyPreprocessingToElements(rootElement: HTMLElement){
        SortCriteriaPreprocessor.adjustDOMElements(rootElement);

        return rootElement.querySelectorAll("*");
    }

    static applyPrebindingOnChildren(rootElement: HTMLElement): void {
        const processedElements = this.applyPreprocessingToElements(rootElement);

        this.nodeListToArray(processedElements)
            .map(element => element as HTMLElement)
            .filter(element => this.processor.canApplyPrebind(element))
            .filter(element => this.processor.hasNotAppliedPrebind(element))
            .forEach(element => this.processor.applyToElement(element));
    }

    private static nodeListToArray<TElementType extends Node>(elements: NodeListOf<TElementType>): TElementType[] {
        const array: TElementType[] = [];
        for (var i = 0, length = elements.length; i < length; i++) {
            array.push(elements[i]);
        }
        return array;
    }
}
