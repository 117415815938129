import { IPrebindHelper, IPrebindHelperInitializationArgs } from "../PrebindHelper";
import { IPrebindHandler } from "../PrebindResolver";

export interface IItemIdValueResolver {
    canResolve(itemId: string, element: HTMLElement): boolean;
    getValue(itemId: string, element: HTMLElement): string;
}

export class ItemIdSelectorPrebind implements IPrebindHelper {
    public name: string = "itemIdSelector";

    private resolvers: IItemIdValueResolver[] = [
        new ResolveIfSelector(),
        new ResolveIfItemIdInPage(),
        new UseItemIdSelectorAsIs()
    ];

    public getPrebind(args: IPrebindHelperInitializationArgs): IPrebindHandler {
        return (itemId: string, element: HTMLElement) => {
            const resolver: IItemIdValueResolver = this.getFirstValidResolver(itemId, element);

            return resolver.getValue(itemId, element);
        };
    }

    private getFirstValidResolver(itemId: string, element: HTMLElement): IItemIdValueResolver {
        let resolver: IItemIdValueResolver;

        do {
            if (this.resolvers.length === 0) {
                const message = "Could not resolve the element using the current resolver. Ensure that a valid value is set.";
                console.error(message, {
                    itemId: itemId,
                    element: element
                });
                throw message;
            }
            resolver = this.resolvers.shift();
        } while (!resolver.canResolve(itemId, element));

        return resolver;
    }
}

export class ResolveIfItemIdInPage implements IItemIdValueResolver {
    canResolve(itemId: string): boolean {
        return this.isItemDefined(itemId) &&
            this.isIdSelectorValid(`#${itemId}`);
    }

    getValue(itemId: string): string {
        return `#${itemId}`;
    }

    private isItemDefined(itemId: string): boolean {
        return !!itemId;
    }

    private isIdSelectorValid(selector: string): boolean {
        return document.querySelector(selector) !== null;
    }
}

export class ResolveIfSelector implements IItemIdValueResolver {
    canResolve(itemId: string, element: HTMLElement): boolean {
        return !!itemId &&
            this.isStartingWithSelectorCharacter(itemId);
    }

    getValue(itemId: string, element: HTMLElement): string {
        return itemId;
    }

    private isStartingWithSelectorCharacter(itemId: string): boolean {
        const firstCharacter = itemId[0];

        return [".", "#"].indexOf(firstCharacter) !== -1;
    }
}


export class UseItemIdSelectorAsIs implements IItemIdValueResolver {
    canResolve(itemId: string, element: HTMLElement): boolean {
        return true;
    }

    getValue(itemId: string, element: HTMLElement): string {
        return itemId;
    }
}
