import { SearchInterfacesProvider } from "../../utils/SearchInterfaceProvider";
import { ElementWithContextInitializer } from "./ElementWithContextInitializer";

import {
    $$,
    Dom,
    get as getCoveoComponents,
    initSearchbox,
    InitializationEvents
} from "coveo-search-ui";

export class SearchboxInitializer {
    private searchInterfacesProvider = new SearchInterfacesProvider();

    async initSearchboxIfStandalone(searchboxElement: HTMLElement, searchPageUri?: string, options?: any): Promise<void> {
        const searchInterfaces = this.searchInterfacesProvider.getSearchInterfacesInPage(["Recommendation", "SearchInterface"]);
        await this.waitForSearchInterfacesInitialization(searchInterfaces);
        if (this.searchboxElementIsNotInitialized(searchboxElement)) {
            if (!!searchPageUri) {
                ElementWithContextInitializer.initializeContextWithinElement(searchboxElement.parentElement);
                // coveo-search-ui doesn't like an initSearchbox directly on the searchbox.
                initSearchbox(searchboxElement.parentElement, searchPageUri, options);
            } else {
                console.warn(`The search box is not included in any search interface and the "searchPageUri" parameters is not specified. Consider using a Global Searchbox instead.`, searchboxElement);
            }
        }
    }

    private searchboxElementIsNotInitialized(searchBoxElement: HTMLElement): boolean {
        return typeof (getCoveoComponents(searchBoxElement, Coveo.Searchbox)) === "undefined";
    }

    private async waitForSearchInterfacesInitialization(searchInterfaces: HTMLElement[]): Promise<void> {
        await Promise.all(this.getAllSearchInterfacesAfterComponentInitialization(searchInterfaces));
    }

    private getAllSearchInterfacesAfterComponentInitialization(searchInterfaces: HTMLElement[]): Promise<void>[] {
        return searchInterfaces.map(searchInterface => $$(searchInterface))
            .map(searchInterface => this.waitForSearchInterfaceInitialization(searchInterface));
    }

    private waitForSearchInterfaceInitialization(searchInterface: Dom): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (searchInterface.hasClass("coveo-after-initialization")) {
                return resolve();
            }

            searchInterface.on(InitializationEvents.afterComponentsInitialization, () => resolve());
        });
    }
}
