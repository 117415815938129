import { SearchEndpoint as CoveoSearchEndpoint } from "coveo-search-ui";

export interface ICoveoForSitecoreEndpointOptions {
    restEndpointUri?: string;
    itemUri: string;
    siteName: string;
}

export class SearchEndpoint {
    static configureSitecoreEndpoint(options: ICoveoForSitecoreEndpointOptions): void {
        if (typeof (options) === "undefined") {
            throw Error("You must define endpoint options when calling 'configureSitecoreEndpoint'.");
        }

        SearchEndpoint.getAccessToken().then(accessToken => {
            SearchEndpoint.initializeEndPoint(options, accessToken);
            SearchEndpoint.dispatchInitializationEvent();
        });
    }

    static initializeEndPoint(options: ICoveoForSitecoreEndpointOptions, accessToken: string): void {
        const endpoint = SearchEndpoint.createNewEndpoint(options, accessToken);
        CoveoSearchEndpoint.endpoints["default"] = endpoint;
    }

    static dispatchInitializationEvent() {
        const event = document.createEvent("CustomEvent");
        event.initEvent("CoveoSearchEndpointInitialized", false, true);
        document.dispatchEvent(event);
    }

    static getAccessToken() {
        return SearchEndpoint.fetchAccessToken()
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => data.token)
            .catch(() => {
                // Returning an empty token delegates error handling of invalid access token to the Coveo Search UI.
                return "";
            });
    }

    static fetchAccessToken() {
        return fetch('/coveo/rest/token');
    }

    private static createNewEndpoint(options: ICoveoForSitecoreEndpointOptions, accessToken: string): CoveoSearchEndpoint {
        return new CoveoSearchEndpoint({
            restUri: options.restEndpointUri ? `${options.restEndpointUri}/search` : '/coveo/rest/search',
            queryStringArguments: {
                "sitecoreItemUri": options.itemUri,
                "siteName": options.siteName
            },
            accessToken: accessToken,
            renewAccessToken: SearchEndpoint.getAccessToken
        });
    }
}
