import { Context } from "../../base/Context";

const CSS_SORT_BOTH_DIRECTIONS = '.CoveoSort[data-sc-enable-both-sort-directions="true"]';
const CSS_DROPDOWN_SELECTOR = '.CoveoSortDropdown';
const DESCENDING_CRITERIA = 'Descending';
const ASCENDING_CRITERIA = 'Ascending';

const createReversedSortElement = (sortElement: HTMLElement): HTMLElement => {
    const sortElementReverse: HTMLElement = sortElement.cloneNode(false) as HTMLElement;
    const sortCriteria = JSON.parse(sortElementReverse.dataset.sortCriteria)

    sortCriteria.direction = sortCriteria.direction === DESCENDING_CRITERIA
        ? ASCENDING_CRITERIA
        : DESCENDING_CRITERIA

    sortElementReverse.dataset.sortCriteria = JSON.stringify(sortCriteria);

    return sortElementReverse;
}

const addDirectionToCaption = (element: HTMLElement): void => {
    const sortCriteria = JSON.parse(element.dataset.sortCriteria)

    element.dataset.caption = sortCriteria.direction === DESCENDING_CRITERIA
        ? `${element.dataset.caption} ${Context.labels.translate("Descending")}`
        : `${element.dataset.caption} ${Context.labels.translate("Ascending")}`
}

const adjustSortDropdown = (rootElement: HTMLElement): void => {
    const dropdownElement: HTMLElement = rootElement.querySelector(CSS_DROPDOWN_SELECTOR);

    if (dropdownElement) {
        for (const sortElement of rootElement.querySelectorAll(CSS_SORT_BOTH_DIRECTIONS) as any) {
            const sortElementReverse: HTMLElement = createReversedSortElement(sortElement);

            sortElement.insertAdjacentElement("afterend", sortElementReverse);

            addDirectionToCaption(sortElement);
            addDirectionToCaption(sortElementReverse);
        }
    }
}

export const adjustDOMElements = (rootElement: HTMLElement): void => {
    adjustSortDropdown(rootElement);
}