import { ISearchInterfaceComponentInitializer, getInitializerInstance } from "../../resolvers/components/SearchInterfaceComponentInitializer";
import { ISitecoreExperienceEditor, getExperienceEditorInstance } from "./SitecoreExperienceEditor";
import { SearchInterfacesProvider } from "../../utils/SearchInterfaceProvider";

import {
    SearchInterface,
    get as getCoveoComponent
} from "coveo-search-ui";


export class ExternalComponentInitializer {
    private searchInterfacesProvider = new SearchInterfacesProvider();

    constructor(private experienceEditor: ISitecoreExperienceEditor = getExperienceEditorInstance(), private searchInterfaceComponentInitializer: ISearchInterfaceComponentInitializer = getInitializerInstance()) {
    }

    public initializeExternalComponentSection(externalComponentsSection: HTMLElement): void {
        const searchInterface = this.findSearchInterfaceForSection(externalComponentsSection);
        if (!!searchInterface) {
            this.searchInterfaceComponentInitializer.loadComponentsWithSearchInterfaceInitialization(searchInterface, externalComponentsSection);
        } else {
            const getAllSearchInterfaceIds = this.getAllSearchInterfaceIdsInPage();
            if (getAllSearchInterfaceIds.length > 0) {
                console.error(`Could not find the search interface element with the following ID: ${this.getSearchInterfaceId(externalComponentsSection)}. The following IDs were found: ${getAllSearchInterfaceIds.join(",")}.`, externalComponentsSection);
            }
        }
    }

    public initializeExperienceEditorHooksForExternalComponentsSection(externalComponentsSection: HTMLElement): void {
        const searchInterface = this.findSearchInterfaceForSection(externalComponentsSection);
        if (!!searchInterface) {
            this.experienceEditor.registerOnLoadRenderingFromUrl(callbackData => {
                const component = this.experienceEditor.getComponentFromLoadRenderingFromUrlCallbackData(callbackData);

                if (externalComponentsSection.contains(component)) {
                    this.searchInterfaceComponentInitializer.loadComponentsForSearchInterface(getCoveoComponent(searchInterface, SearchInterface) as SearchInterface, component);
                }
            });
        }
    }

    private findSearchInterfaceForSection(externalComponentsSection: HTMLElement): HTMLElement {
        const searchInterfaceId = this.getSearchInterfaceId(externalComponentsSection);
        const searchInterfaceElement = document.getElementById(searchInterfaceId);
        if (!!searchInterfaceElement) {
            return searchInterfaceElement;
        }
    }

    private getSearchInterfaceId(externalComponentsSection: HTMLElement): string {
        return externalComponentsSection.dataset.searchInterfaceId;
    }

    private getAllSearchInterfaceIdsInPage(): string[] {
        return this.searchInterfacesProvider
            .getSearchInterfacesInPage(["SearchInterface"])
            .map(element => element.id)
            .filter(id => !!id);
    }
}
